import React from 'react';
import { Chip } from '@mui/joy';

interface IOrganizationStatusChipProps {
  status?: string;
}

const variants: { [key: string]: any } = {
  active: {
    type: 'success',
    label: 'Active',
  },
  blocked: {
    type: 'danger',
    label: 'Blocked',
  },
  pending: {
    type: 'primary',
    label: 'Pending',
  },
};

export default class OrganizationStatusChip extends React.Component<IOrganizationStatusChipProps> {
  constructor(props: IOrganizationStatusChipProps) {
    super(props);
  }

  render(): React.ReactNode {
    if (!this.props.status) {
      return null;
    }

    return (
      <Chip
        color={
          variants[this.props.status]
            ? variants[this.props.status].type
            : 'neutral'
        }
      >
        {variants[this.props.status] ? variants[this.props.status].label : ''}
      </Chip>
    );
  }
}
