import React from 'react';
import ReactPaginate from 'react-paginate';
import {
  Option,
  Select,
  Stack,
  Typography,
  selectClasses,
  styled,
} from '@mui/joy';
import {
  ChevronDown,
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreHorizontalIcon,
} from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { colors } from '../../configs/theme';

interface IPaginationProps {
  pageIndex?: number;
  pageSize?: number;
  totalRows?: number;
  totalPages?: number;
  onChangePageSize?: (pageSize: number) => void;
  onChangePageIndex?: (pageIndex: number) => void;
  onFirstPage?: () => void;
  onPreviousPage?: () => void;
  onLastPage?: () => void;
  onNextPage?: () => void;
}

const Paginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    margin-right: 4px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      color: ${colors.gray700};
      background-color: ${colors.white};
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }

    &.selected {
      a {
        background-color: ${colors.raisinBlack};
        color: ${colors.white};
      }
    }
  }
`;

const Pagination: React.FC<IPaginationProps> = ({
  pageIndex = 0,
  pageSize = 10,
  totalPages = 0,
  totalRows = 0,
  onChangePageSize,
  onChangePageIndex,
}) => {
  const isSm = useMediaQuery({ query: '(max-width: 780px)' });

  return (
    <Stack
      direction="row"
      justifyContent={isSm ? 'space-between' : 'flex-end'}
      alignItems="center"
      p={1}
      spacing={4}
    >
      <Stack direction="row" alignItems="center" spacing={4}>
        {isSm ? null : (
          <Typography fontSize={12} textColor="gray700">
            {pageIndex * pageSize + 1} ~ {(pageIndex + 1) * pageSize} of{' '}
            {totalRows} entries
          </Typography>
        )}
        <Select
          variant="plain"
          value={pageSize}
          renderValue={(value) => `${value?.value} per page`}
          onChange={(e, value) => {
            if (onChangePageSize) {
              onChangePageSize(Number(value));
            }
          }}
          indicator={<ChevronDown size={14} color={colors.gray700} />}
          sx={{
            p: 0,
            px: 0.5,
            backgroundColor: 'transparent !important',
            borderWidth: '0 !important',
            minHeight: 24,
            height: 24,
            '& .MuiSelect-button': {
              fontSize: 12,
              color: colors.gray700,
            },
            [`& ${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
        >
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
        </Select>
      </Stack>
      <Paginate
        forcePage={pageIndex}
        pageCount={totalPages}
        pageRangeDisplayed={isSm ? 1 : 2}
        marginPagesDisplayed={1}
        onPageChange={(event) => {
          if (onChangePageIndex) {
            onChangePageIndex(event.selected);
          }
        }}
        breakLabel={<MoreHorizontalIcon size={14} />}
        previousLabel={<ChevronLeftIcon size={14} />}
        nextLabel={<ChevronRightIcon size={14} />}
      />
    </Stack>
  );
};

export default Pagination;
