import { Components, Theme } from '@mui/joy';
import { colors } from '../theme';

export const JoyCard: Components<Theme>['JoyCard'] = {
  styleOverrides: {
    root: () => ({
      backgroundColor: colors.white,
      padding: 16,
      border: 'none',
    }),
  },
};
