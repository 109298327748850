import { Components, Theme } from '@mui/joy';
import { colors } from '../theme';

export const JoyCheckbox: Components<Theme>['JoyCheckbox'] = {
  styleOverrides: {
    root: () => ({}),
    action: () => ({}),
    checkbox: ({ ownerState }) => ({
      ...(ownerState.size === 'md' && {
        width: 20,
        height: 20,
        borderWidth: 2,
        borderColor: '#9F9E9E',
      }),
      ...(ownerState.checked && {
        backgroundColor: colors.green,
      }),

      '&::after': {
        content: '""',
        display: 'block',
        width: 0,
        height: 0,
        backgroundColor: `${colors.icterine}80`,
        borderRadius: 40,
        position: 'absolute',
        left: 0,
        top: 0,
        transition: 'all 0.2s',
      },
      '&:hover': {
        backgroundColor: ownerState.checked ? colors.green : 'transparent',
        '&::after': {
          content: '""',
          display: 'block',
          width: 40,
          height: 40,
          backgroundColor: `${colors.icterine}30`,
          borderRadius: 40,
          position: 'absolute',
          left: -10,
          top: -10,
          transition: 'all 0.2s',
        },
      },
    }),
    label: ({ ownerState }) => ({
      fontSize: 16,
      color: colors.raisinBlack,
      ...(ownerState.disabled && {
        color: colors.gray600,
      }),
    }),
  },
};
