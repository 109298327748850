import React, { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/joy';
import { Outlet } from 'react-router-dom';

const AuthLayout: FC<PropsWithChildren> = () => {
  return (
    <Stack
      width={1}
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Outlet />
    </Stack>
  );
};

export default AuthLayout;
