import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Select,
  Option,
} from '@mui/joy';
import { PieChartIcon } from 'lucide-react';
import DashboardChartCard from '../../components/widgets/DashboardChartCard';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import {
  apiGetWMOChart,
  apiGetProducerChart,
  apiGetWasteChart,
  apiGetFeeChart,
  apiGetTopWMOList,
  apiGetTopCountriesChart,
  apiGetTotalRechargesChart,
  apiGetFeesVsCreditChart,
  apiGetWasteCollectedChart,
  apiGetFeesCollectedChart,
} from '../../apis/dashboard';
import DataTable from '../../components/datatable/DataTable';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { ITopWMOModel } from '../../apis/types';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import moment from 'moment';

countries.registerLocale(en);

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DashboardPage: FC = () => {
  const isSm = useMediaQuery({ query: '(max-width: 1224px)' });
  const [totalOrg, setTotalOrg] = useState<number>(0);
  const [totalWaste, setTotalWaste] = useState<number>(0);
  const [totalFees, setTotalFees] = useState<number>(0);
  const [rechargeType, setRechargeType] = useState<string>('week');
  const [totalFeesCredit, setTotalFeesCredit] = useState<any>({
    totalFees: 0,
    totalCredit: 0,
  });

  const [wasteCollectType, setWasteCollectType] = useState<string>('week');
  const [feesCollectType, setFeesCollectType] = useState<string>('week');

  const { data: wmoChartData, isLoading: wmoChartLoading } = useQuery({
    queryKey: ['wmoChart'],
    queryFn: async () => {
      const data = await apiGetWMOChart();
      return data;
    },
  });

  const { data: producerChartData, isLoading: producerChartLoading } = useQuery(
    {
      queryKey: ['producerChart'],
      queryFn: async () => {
        const data = await apiGetProducerChart();
        return data;
      },
    },
  );

  const { data: wasteChartData, isLoading: wasteChartLoading } = useQuery({
    queryKey: ['wasteChart'],
    queryFn: async () => {
      const data = await apiGetWasteChart();
      return data;
    },
  });

  const { data: feeChartData, isLoading: feeChartLoading } = useQuery({
    queryKey: ['feeChart'],
    queryFn: async () => {
      const data = await apiGetFeeChart();
      return data;
    },
  });

  const { data: topWmoData, isLoading: topWmoLoading } = useQuery({
    queryKey: ['topWMOList'],
    queryFn: async () => {
      const data = await apiGetTopWMOList();
      return data;
    },
  });

  const { data: topCountriesChartData, isLoading: topCountriesChartLoading } =
    useQuery({
      queryKey: ['topCountriesChart'],
      queryFn: async () => {
        const data = await apiGetTopCountriesChart();
        return data;
      },
    });

  const {
    data: totalRechargesChartData,
    isLoading: totalRechargesChartLoading,
  } = useQuery({
    queryKey: ['totalRechargesChart', rechargeType],
    queryFn: async () => {
      const list: { label: string; total: number }[] = [];
      const data = await apiGetTotalRechargesChart({ type: rechargeType });

      if (rechargeType === 'quarter') {
        data?.items.forEach((item: any) => {
          list.push({
            label: `${moment(item.startOfWeek).format('MM/DD/YYYY')} ~ ${moment(item.endOfWeek).format('MM/DD/YYYY')} `,
            total: item.total,
          });
        });
      } else if (rechargeType === 'year') {
        data?.items.forEach((item: any) => {
          list.push({
            label: `${item.month}/${item.year}`,
            total: item.total,
          });
        });
      } else {
        data?.items.forEach((item: any) => {
          list.push({
            label: `${item.month}/${item.day}/${item.year}`,
            total: item.total,
          });
        });
      }
      return { total: data.total, items: list };
    },
  });

  const { data: feesVsCreditChartData, isLoading: feesVsCreditChartLoading } =
    useQuery({
      queryKey: ['feesVsCreditChart'],
      queryFn: async () => {
        const data = await apiGetFeesVsCreditChart();

        const totalFees = data?.reduce(
          (acc: number, cur: any) => acc + cur.fees,
          0,
        );

        const totalCredit = data?.reduce(
          (acc: number, cur: any) => acc + cur.credit,
          0,
        );

        setTotalFeesCredit({ totalFees, totalCredit });

        return data;
      },
    });

  const { data: wasteCollectChartData, isLoading: wasteCollectChartLoading } =
    useQuery({
      queryKey: ['wasteCollectChart', wasteCollectType],
      queryFn: async () => {
        const list: any[] = [];
        const data = await apiGetWasteCollectedChart({
          type: wasteCollectType,
        });

        if (wasteCollectType === 'year') {
          data?.items.forEach((item: any) => {
            list.push({
              label: `${item.month}/${item.year}`,
              org1: item[data.organizations[0]],
              org2: item[data.organizations[1]],
              org3: item[data.organizations[2]],
              org4: item[data.organizations[3]],
              org5: item[data.organizations[4]],
            });
          });
        } else {
          data?.items.forEach((item: any) => {
            list.push({
              label: `${item.month}/${item.day}/${item.year}`,
              org1: item[data.organizations[0]],
              org2: item[data.organizations[1]],
              org3: item[data.organizations[2]],
              org4: item[data.organizations[3]],
              org5: item[data.organizations[4]],
            });
          });
        }

        return {
          organizations: data.organizations,
          items: list,
        };
      },
    });

  const { data: feesCollectChartData, isLoading: feesCollectChartLoading } =
    useQuery({
      queryKey: ['feesCollectChart', feesCollectType],
      queryFn: async () => {
        const list: any[] = [];
        const data = await apiGetFeesCollectedChart({
          type: feesCollectType,
        });

        if (feesCollectType === 'year') {
          data?.items.forEach((item: any) => {
            list.push({
              label: `${item.month}/${item.year}`,
              org1: item[data.organizations[0]],
              org2: item[data.organizations[1]],
              org3: item[data.organizations[2]],
              org4: item[data.organizations[3]],
              org5: item[data.organizations[4]],
            });
          });
        } else {
          data?.items.forEach((item: any) => {
            list.push({
              label: `${item.month}/${item.day}/${item.year}`,
              org1: item[data.organizations[0]],
              org2: item[data.organizations[1]],
              org3: item[data.organizations[2]],
              org4: item[data.organizations[3]],
              org5: item[data.organizations[4]],
            });
          });
        }

        return {
          organizations: data.organizations,
          items: list,
        };
      },
    });

  useEffect(() => {
    if (Array.isArray(topCountriesChartData)) {
      const totalOrg = topCountriesChartData.reduce(
        (acc: number, cur: any) => acc + cur.org,
        0,
      );
      setTotalOrg(totalOrg);

      const totalWaste = topCountriesChartData.reduce(
        (acc: number, cur: any) => acc + cur.waste,
        0,
      );
      setTotalWaste(totalWaste);

      const totalFees = topCountriesChartData.reduce(
        (acc: number, cur: any) => acc + cur.fees,
        0,
      );
      setTotalFees(totalFees);
    }
  }, [topCountriesChartData]);

  const topWmoHelper = useMemo(() => {
    return createColumnHelper<Partial<ITopWMOModel>>();
  }, []);

  const topWmoColumns = useMemo(() => {
    return [
      topWmoHelper.accessor('name', {
        header: 'Name',
        cell: (info) => info.getValue(),
      }),
      topWmoHelper.accessor('country', {
        header: 'Country',
        cell: (info) => countries.getName(String(info.getValue()), 'en'),
      }),
      topWmoHelper.accessor('producer', {
        header: 'Producers',
        cell: (info) => new Intl.NumberFormat().format(info.getValue() ?? 0),
      }),
      topWmoHelper.accessor('waste', {
        header: 'Waste Collections (Kg)',
        cell: (info) => new Intl.NumberFormat().format(info.getValue() ?? 0),
      }),
      topWmoHelper.accessor('fees', {
        header: 'Fees Collected',
        cell: (info) => new Intl.NumberFormat().format(info.getValue() ?? 0),
      }),
    ];
  }, [topWmoHelper]);

  const topWmoTable = useReactTable({
    data: topWmoData ?? [],
    columns: topWmoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Box
      sx={{ paddingLeft: '32px', paddingTop: '32px', paddingBottom: '32px' }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <PieChartIcon />
          <Typography level="h3">Dashboard</Typography>
        </Stack>
      </Stack>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <DashboardChartCard
              title="WMOs"
              total={new Intl.NumberFormat().format(wmoChartData?.total ?? 0)}
              loading={wmoChartLoading}
              percent={wmoChartData?.percent}
              items={wmoChartData?.items}
            />
          </Grid>
          <Grid>
            <DashboardChartCard
              title="Producers"
              total={new Intl.NumberFormat().format(
                producerChartData?.total ?? 0,
              )}
              loading={producerChartLoading}
              percent={producerChartData?.percent}
              items={producerChartData?.items}
            />
          </Grid>
          <Grid>
            <DashboardChartCard
              title="Waste Collected"
              total={`${new Intl.NumberFormat().format(
                wasteChartData?.total ?? 0,
              )} kg`}
              loading={wasteChartLoading}
              percent={wasteChartData?.percent}
              items={wasteChartData?.items}
              description="Last week"
            />
          </Grid>
          <Grid>
            <DashboardChartCard
              title="Fees Collected"
              total={`$ ${new Intl.NumberFormat().format(
                feeChartData?.total ?? 0,
              )}`}
              loading={feeChartLoading}
              percent={feeChartData?.percent}
              items={feeChartData?.items}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack direction={isSm ? 'column' : 'row'} spacing={4} mt={4}>
        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Total Recharges</Typography>

                <Select
                  value={rechargeType}
                  onChange={(e, newValue: any) => setRechargeType(newValue)}
                >
                  <Option value="week">Last Week</Option>
                  <Option value="month">Last Month</Option>
                  <Option value="quarter">Last Quarter</Option>
                  <Option value="year">Year-to-Date</Option>
                </Select>
              </Stack>

              <Stack direction="row" gap={1} alignItems="end">
                <Typography level="h1">
                  $
                  {new Intl.NumberFormat().format(
                    totalRechargesChartData?.total ?? 0,
                  )}
                </Typography>
                <Typography level="title-sm" sx={{ color: '#BAB5B3' }}>
                  year-to-date
                </Typography>
              </Stack>

              <LineChart
                xAxis={[{ dataKey: 'label', scaleType: 'band' }]}
                series={[{ dataKey: 'total', showMark: false }]}
                height={300}
                loading={totalRechargesChartLoading}
                dataset={totalRechargesChartData?.items ?? []}
                leftAxis={null}
                colors={['#00195A']}
              />
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography level="h4">
                  Fees Collected vs. Credit Used
                </Typography>
              </Stack>
              <BarChart
                dataset={feesVsCreditChartData ?? []}
                height={332}
                yAxis={[
                  {
                    scaleType: 'band',
                    dataKey: 'month',
                    valueFormatter: (v: any): string => months[v - 1],
                  },
                ]}
                series={[
                  { dataKey: 'fees', label: 'Total Fees Collected' },
                  { dataKey: 'credit', label: 'Total Credit Used' },
                ]}
                slotProps={{ legend: { hidden: true } }}
                colors={['#2CB53B', '#D64C56']}
                layout="horizontal"
                grid={{ horizontal: true }}
                loading={feesVsCreditChartLoading}
              />
              <Grid container spacing={5} ml={4}>
                <Grid>
                  <Typography sx={{ color: '#2CB53B' }}>
                    Total Fees Collected{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      $
                      {new Intl.NumberFormat().format(
                        totalFeesCredit.totalFees,
                      )}
                    </span>
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ color: '#D64C56' }}>
                    Total Credit Used{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {new Intl.NumberFormat().format(
                        totalFeesCredit.totalCredit,
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Stack>

      <Box mt={4}>
        <Card>
          <CardContent>
            <Typography level="h4">Top 5 WMO Performers</Typography>
            <DataTable
              table={topWmoTable}
              pagination={false}
              loading={topWmoLoading}
            />
          </CardContent>
        </Card>
      </Box>
      {Array.isArray(topCountriesChartData) ? (
        <Box mt={4}>
          <Card>
            <CardContent>
              <Typography level="h4">
                Top 5 Countries by WMOs, Waste Coollected, and Fees Coollected
              </Typography>

              <BarChart
                height={300}
                dataset={topCountriesChartData ?? []}
                loading={topCountriesChartLoading}
                xAxis={[
                  {
                    scaleType: 'band',
                    dataKey: 'country',
                    valueFormatter: (v: any): string =>
                      countries.getName(String(v), 'en') ?? '',
                  },
                ]}
                colors={['#77829F', '#021B58', '#9F9E9E']}
                slotProps={{ legend: { hidden: true } }}
                series={[
                  { dataKey: 'org', label: 'Numbers of WMOs' },
                  {
                    dataKey: 'waste',
                    label: 'Waste Collected',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'fees',
                    label: 'Fees Collected',
                    valueFormatter: (value: any) => `$${value}`,
                  },
                ]}
                margin={{ left: 60 }}
              />

              <Grid container spacing={5} ml={4}>
                <Grid>
                  <Typography sx={{ color: '#77829F' }}>
                    Numbers of WMOs {new Intl.NumberFormat().format(totalOrg)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ color: '#021B58' }}>
                    Total Waste Collected{' '}
                    {new Intl.NumberFormat().format(totalWaste)} Kg
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ color: '#9F9E9E' }}>
                    Total Fees Collected $
                    {new Intl.NumberFormat().format(totalFees)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : null}

      <Stack direction={isSm ? 'column' : 'row'} spacing={4} mt={4}>
        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Waste Collected Over Time</Typography>

                <Select
                  value={wasteCollectType}
                  onChange={(e, newValue: any) => setWasteCollectType(newValue)}
                >
                  <Option value="week">Last Week</Option>
                  <Option value="month">Last Month</Option>
                  <Option value="year">Year-to-Date</Option>
                </Select>
              </Stack>

              <LineChart
                xAxis={[{ dataKey: 'label', scaleType: 'band' }]}
                tooltip={{
                  trigger: 'item',
                }}
                series={[
                  {
                    dataKey: 'org1',
                    label: 'Waste Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org2',
                    label: 'Waste Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org3',
                    label: 'Waste Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org4',
                    label: 'Waste Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org5',
                    label: 'Waste Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                ]}
                slotProps={{ legend: { hidden: true } }}
                height={300}
                loading={wasteCollectChartLoading}
                dataset={wasteCollectChartData?.items ?? []}
                colors={['#021B58', '#77829F', '#475A82', '#47455E', '#9F9E9E']}
                margin={{ left: 60 }}
              />
              <Grid
                container
                spacing={1}
                sx={{ justifyContent: 'space-between' }}
              >
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#021B58',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[0]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#77829F',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[1]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#475A82',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[2]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#47455E',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[3]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#9F9E9E',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[4]}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Fees Collected Over Time</Typography>

                <Select
                  value={feesCollectType}
                  onChange={(e, newValue: any) => setFeesCollectType(newValue)}
                >
                  <Option value="week">Last Week</Option>
                  <Option value="month">Last Month</Option>
                  <Option value="year">Year-to-Date</Option>
                </Select>
              </Stack>

              <LineChart
                xAxis={[{ dataKey: 'label', scaleType: 'band' }]}
                tooltip={{
                  trigger: 'item',
                }}
                series={[
                  {
                    dataKey: 'org1',
                    label: 'Fees Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org2',
                    label: 'Fees Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org3',
                    label: 'Fees Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org4',
                    label: 'Fees Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                  {
                    dataKey: 'org5',
                    label: 'Fees Collected: ',
                    valueFormatter: (value: any) => `${value} kg`,
                  },
                ]}
                slotProps={{ legend: { hidden: true } }}
                height={300}
                loading={feesCollectChartLoading}
                dataset={feesCollectChartData?.items ?? []}
                colors={['#021B58', '#77829F', '#475A82', '#47455E', '#9F9E9E']}
                margin={{ left: 80 }}
              />
              <Grid
                container
                spacing={1}
                sx={{ justifyContent: 'space-between' }}
              >
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#021B58',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[0]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#77829F',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[1]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#475A82',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[2]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#47455E',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[3]}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#9F9E9E',
                        borderRadius: '50%',
                        marginRight: 1,
                      }}
                    />
                    <Typography>
                      {wasteCollectChartData?.organizations[4]}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardPage;
