import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
  Button,
} from '@mui/joy';
import { useParams } from 'react-router';
import { Spinner } from '../../../../components/indicators/Spinner';
import {
  apiGetInfoOrganizationById,
  apiGetOrganization,
  apiGetUserListByOrg,
} from '../../../../apis/organizations';

import { apiDeleteUser, apiUpdateUserStatus } from '../../../../apis/users';
import { useQuery } from '@tanstack/react-query';
import StatCard from '../../../../components/widgets/StatCard';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import DataTable from '../../../../components/datatable/DataTable';
import UserStatusChip from '../../../../components/chips/UserStatusChip';
import { SlidersVerticalIcon, TrashIcon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';

import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';

import { IUserOrgModel } from '../../../../apis/types';

countries.registerLocale(en);

interface IStatusChangeData {
  id: string;
  name: string;
  status: 'active' | 'blocked';
}

const schema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    status: yup.string().oneOf(['active', 'blocked']).required(),
  })
  .required();

const ProfilePage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const isSm = useMediaQuery({ query: '(max-width: 1224px)' });

  const [modals, setModals] = useState({
    status: false,
    delete: {
      show: false,
      id: '',
    },
  });
  const [updatingId, setUpdatingId] = useState('');
  const [deletingId, setDeletingId] = useState('');

  const { control, handleSubmit, setValue } = useForm<IStatusChangeData>({
    defaultValues: {
      id: '',
      name: '',
      status: 'active',
    },
    resolver: yupResolver(schema),
  });

  const { data: infoData, isLoading: infoLoading } = useQuery({
    queryKey: ['info', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetInfoOrganizationById(id);
        return data;
      }
    },
  });

  const { data: detailData, isLoading: detailLoading } = useQuery({
    queryKey: ['details', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetOrganization(id);
        return data;
      }
    },
  });

  const {
    data: userListData,
    isLoading: userListLoading,
    refetch: userListRefetch,
  } = useQuery({
    queryKey: ['userList', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetUserListByOrg(id);
        return data;
      }
    },
  });

  const handleToggleStatusModal = (values?: IStatusChangeData) => {
    setModals((values) => ({
      ...values,
      status: !values.status,
    }));
    if (values) {
      setValue('id', values.id);
      setValue('name', values.name);
      setValue('status', values.status);
    }
  };

  const handleUpdateUserStatus = async (values: IStatusChangeData) => {
    if (updatingId) {
      return;
    }
    handleToggleStatusModal();
    setUpdatingId(values.id);
    if (await apiUpdateUserStatus(values.id, values.status)) {
      await userListRefetch();
    }
    setUpdatingId('');
  };

  const handleDeleteUser = async (id: string) => {
    if (deletingId || !id) {
      return;
    }

    setModals((values) => ({
      ...values,
      delete: {
        show: false,
        id: '',
      },
    }));

    setDeletingId(id);
    if (await apiDeleteUser(id)) {
      await userListRefetch();
    }
    setDeletingId('');
  };

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IUserOrgModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
      }),
      helper.accessor('user', {
        header: 'Name',
        cell: (info) => info.getValue()?.name,
      }),
      helper.accessor('role', {
        header: 'Role',
        cell: (info) => info.getValue(),
      }),
      helper.display({
        header: 'MFA',
        cell: (info) =>
          info.row.original.user?.twofaEnabled ? (
            <Chip color="success">Enabled</Chip>
          ) : (
            <Chip color="danger">Disabled</Chip>
          ),
        enableSorting: false,
      }),
      helper.display({
        header: 'Status',
        cell: (info) => (
          <UserStatusChip status={info.row.original.user?.status} />
        ),
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() =>
                handleToggleStatusModal({
                  id: info.row.original.user?._id ?? '',
                  name: info.row.original.user?.name ?? '',
                  status:
                    info.row.original.user?.status === 'active'
                      ? 'active'
                      : 'blocked',
                })
              }
            >
              {updatingId === info.row.original.user?._id ? (
                <Spinner size={16} />
              ) : (
                <SlidersVerticalIcon size={16} />
              )}
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() =>
                setModals((values) => ({
                  ...values,
                  delete: {
                    show: true,
                    id: info.row.original.user?._id ?? '',
                  },
                }))
              }
            >
              {deletingId === info.row.original._id ? (
                <Spinner size={16} />
              ) : (
                <TrashIcon size={16} />
              )}
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: userListData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Total Collection Points"
              value={`${new Intl.NumberFormat().format(infoData?.count_of_pickup_points ?? 0)}`}
              variant="dark"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Fees Paid"
              value={`$${new Intl.NumberFormat().format(infoData?.amount_fees ?? 0)}`}
              variant="light-blue"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Remaining Credits"
              value={`${new Intl.NumberFormat().format(infoData?.available_balance ?? 0)}`}
              variant="light-blue"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Team members"
              value={`${new Intl.NumberFormat().format(infoData?.count_member ?? 0)}`}
              variant="light"
              loading={infoLoading}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack gap={2} direction={isSm ? 'column' : 'row'} mt={2}>
        <Card
          variant="outlined"
          sx={{ width: '100%', border: '1px solid #E1E1E1' }}
        >
          <CardContent>
            <Typography level="h3">Identification</Typography>

            {detailLoading ? (
              <Box mt={1.25}>
                <Spinner color={'black'} size={24} />
              </Box>
            ) : (
              <Stack mt={3} gap={2}>
                <Box>
                  <Typography level="title-lg">Company tax name</Typography>
                  <Typography level="body-sm">
                    {detailData?.company_name}
                  </Typography>
                </Box>

                <Box>
                  <Typography level="title-lg">Country</Typography>
                  <Typography level="body-sm">
                    {detailData?.country
                      ? countries.getName(detailData?.country, 'en')
                      : ''}
                  </Typography>
                </Box>

                <Box>
                  <Typography level="title-lg">VAT number</Typography>
                  <Typography level="body-sm">
                    {(detailData?.vat_number_country
                      ? detailData?.vat_number_country
                      : '') +
                      ' ' +
                      (detailData?.vat_number ? detailData?.vat_number : '')}
                  </Typography>
                </Box>

                <Box>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">Official ID:</Typography>
                    <Typography level="body-sm">
                      {(detailData?.official_id_country
                        ? detailData?.official_id_country
                        : '') +
                        ' ' +
                        (detailData?.official_id_country
                          ? detailData?.official_id
                          : '')}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">ID:</Typography>
                    <Typography level="body-sm">
                      {detailData?.id ? detailData?.id : ''}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>
        <Card sx={{ width: '100%', border: '1px solid #E1E1E1' }}>
          <CardContent>
            <Typography level="h3">Location</Typography>
            {detailLoading ? (
              <Box mt={1.25}>
                <Spinner color={'black'} size={24} />
              </Box>
            ) : (
              <Stack mt={3} gap={2}>
                <Typography level="title-lg">Coordinates</Typography>

                <Box>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">Latitude:</Typography>
                    <Typography level="body-sm">
                      {detailData?.latitude ?? ''}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">Longitude:</Typography>
                    <Typography level="body-sm">
                      {detailData?.longitude ?? ''}
                    </Typography>
                  </Stack>
                </Box>

                <Box>
                  <Typography level="title-lg">Address</Typography>
                  <Typography level="body-sm">
                    {detailData?.addr_apartment ?? ''} <br />
                    {detailData?.addr_street ?? ''} <br />
                    {detailData?.addr_postcode ?? ''} <br />
                    {detailData?.addr_city ?? ''}
                    {', '}
                    {detailData?.addr_country ?? ''}
                  </Typography>
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>
        <Card sx={{ width: '100%', border: '1px solid #E1E1E1' }}>
          <CardContent>
            <Typography level="h3">Contacts</Typography>
            {detailLoading ? (
              <Box mt={1.25}>
                <Spinner color={'black'} size={24} />
              </Box>
            ) : (
              <Stack mt={3} gap={2}>
                <Box>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">Phone:</Typography>
                    <Typography level="body-sm">
                      {detailData?.phone ?? ''}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">Mobile Phone:</Typography>
                    <Typography level="body-sm">
                      {detailData?.mobile_phone ?? ''}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography level="title-lg">Email:</Typography>
                    <Typography level="body-sm">
                      {detailData?.email ?? ''}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Stack>

      <Box mt={2}>
        <DataTable table={table} pagination={false} loading={userListLoading} />
      </Box>

      <Modal open={modals.status} onClose={() => handleToggleStatusModal()}>
        <ModalDialog minWidth={320} maxWidth={480}>
          <ModalClose />
          <DialogTitle>Switch User Status</DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              component="form"
              onSubmit={handleSubmit(handleUpdateUserStatus)}
            >
              <Controller control={control} name="id" render={() => <></>} />
              <Controller
                control={control}
                name="name"
                render={({ field: { value } }) => (
                  <FormControl required>
                    <FormLabel>User</FormLabel>
                    <Input value={value} readOnly />
                  </FormControl>
                )}
              />
              <Box mt={2}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <FormControl required>
                      <FormLabel>Status</FormLabel>
                      <Select
                        value={field.value}
                        onChange={(ev, value) => field.onChange(value)}
                      >
                        <Option value="active">Active</Option>
                        <Option value="blocked">Blocked</Option>
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
              <Box mt={2}>
                <Button fullWidth type="submit">
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>

      <ConfirmationDialog
        open={modals.delete.show}
        title="Delete User"
        description="Are you sure you want to delete this user? This action cannot be undone."
        onPositive={() => handleDeleteUser(modals.delete.id)}
        onNegative={() =>
          setModals((values) => ({
            ...values,
            delete: {
              show: false,
              id: '',
            },
          }))
        }
      />
    </>
  );
};

export default ProfilePage;
