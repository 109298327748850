import React, { FC } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { colors } from '../../configs/theme';

interface ISpinnerProps {
  size?: number;
  color?: string;
  scheme?: 'primary' | 'secondary' | 'light';
}

export const Spinner: FC<ISpinnerProps> = ({ size = 24, color, scheme }) => (
  <RotatingLines
    width={size ? size.toString() : '20'}
    strokeWidth="2"
    strokeColor={
      color
        ? color
        : scheme === 'primary'
          ? colors.raisinBlack
          : scheme === 'secondary'
            ? colors.icterine
            : scheme === 'light'
              ? colors.white
              : 'currentColor'
    }
    visible
    ariaLabel="loading"
  />
);
