import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IAuthState {
  id: string | null;
  name: string | null;
  email: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  isSessionRemembered: boolean;
}

const initialState: IAuthState = {
  id: null,
  name: null,
  email: null,
  accessToken: null,
  refreshToken: null,
  isSessionRemembered: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as IAuthState,
  reducers: {
    login(state, action: PayloadAction<IAuthState>) {
      return action.payload;
    },
    logout() {
      return initialState;
    },
  },
});

export const { login, logout } = authSlice.actions;
