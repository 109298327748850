import { Components, Theme } from '@mui/joy';
import { colors } from '../theme';

export const JoyChip: Components<Theme>['JoyChip'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      borderRadius: 8,
      fontSize: 10,
      paddingLeft: 10,
      paddingRight: 10,
      height: 20,

      ...(ownerState.variant === 'outlined' && {
        backgroundColor: 'transparent',
      }),
      ...(ownerState.variant === 'soft' && {
        ...(ownerState.color === 'success' && {
          backgroundColor: `${colors.green}1A`,
          color: colors.green,
        }),
        ...(ownerState.color === 'danger' && {
          backgroundColor: `${colors.red}1A`,
          color: colors.red,
        }),
      }),
    }),
  },
};
