import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IOrganizationModel } from './types';

export const apiGetOrganizations = async ({
  keyword = '',
  pageIndex,
  pageSize,
  sortBy,
  sortOrder,
}: {
  keyword?: string | null;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
}) => {
  try {
    const response = await axios.post<{
      tatal_count?: number;
      total_count?: number;
      data: IOrganizationModel[];
    }>('/api/orgs', {
      page: pageIndex,
      limit: pageSize,
      ...(sortBy &&
        sortOrder && {
          sort: [{ field: sortBy, order: sortOrder }],
        }),
      ...(keyword && {
        keyword,
      }),
    });

    return {
      rows: response.data.data,
      total: response.data.tatal_count
        ? response.data.tatal_count
        : response.data.total_count
          ? response.data.total_count
          : 0,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load organizations data', { variant: 'error' });
    return {
      total: 0,
      rows: [],
    };
  }
};
export const apiGetAllOrganizations = async () => {
  try {
    const response = await axios.post('/api/orgs');

    return response.data.data;
  } catch (e) {
    enqueueSnackbar('Failed to load organizations data', { variant: 'error' });
    return [];
  }
};

export const apiGetOrganization = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/${id}`);

    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to load organization data', { variant: 'error' });
    return [];
  }
};

export const apiUpdateOrgStatus = async (id: string, status: string) => {
  try {
    await axios.put(`/api/orgs/${id}`, {
      blocked: status === 'blocked',
    });
    enqueueSnackbar('Successfully updated the selected organization', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to updated the selected organization', {
      variant: 'error',
    });
    return false;
  }
};

export const apiDeleteOrganization = async (id: string) => {
  try {
    await axios.delete(`/api/orgs/${id}`);
    enqueueSnackbar('Successfully deleted the selected organization', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to delete the selected organization', {
      variant: 'error',
    });
    return false;
  }
};

export const apiGetInfoOrganizationById = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/profile/${id}/info`);
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to load organization data', { variant: 'error' });
    return null;
  }
};

export const apiGetUserListByOrg = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/profile/${id}/users`);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const apiUpdateBalance = async (id: string, amount: number) => {
  try {
    await axios.put(`/api/orgs/info/${id}`, { amount });
    enqueueSnackbar('Successfully updated the balance', { variant: 'success' });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update the balance', { variant: 'error' });
    return false;
  }
};

export const apiGetDashboardProducerChart = async (id: string) => {
  try {
    const response = await axios.get(
      `/api/orgs/dashboard/${id}/producer-chart`,
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetDashboardWasteChart = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/dashboard/${id}/waste-chart`);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetDashboardfeeChart = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/dashboard/${id}/fee-chart`);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetDashboardWasteOverChart = async (
  id: string,
  type: string,
) => {
  try {
    const response = await axios.post(
      `/api/orgs/dashboard/${id}/waste-over-chart`,
      {
        type,
      },
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetDashboardFeesOverChart = async (
  id: string,
  type: string,
) => {
  try {
    const response = await axios.post(
      `/api/orgs/dashboard/${id}/fees-over-chart`,
      {
        type,
      },
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetBalanceInfo = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/balance/${id}/info`);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetBalanceTransactions = async (
  id: string,
  page: number,
  limit: number,
) => {
  try {
    const response = await axios.post(`/api/orgs/balance/${id}/transactions`, {
      page: page + 1,
      limit,
    });
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetCollectionInfo = async (id: string) => {
  try {
    const response = await axios.get(`/api/orgs/collection/${id}/info`);
    return response.data;
  } catch (e) {
    return null;
  }
};

export const apiGetCollectionPickup = async (
  id: string,
  page: number,
  limit: number,
) => {
  try {
    const response = await axios.post(`/api/orgs/collection/${id}/pickup`, {
      page: page + 1,
      limit,
    });
    return response.data;
  } catch (e) {
    return null;
  }
};
