import React from 'react';
import { Components, Theme } from '@mui/joy';
import { Spinner } from '../../components/indicators/Spinner';
import { colors } from '../theme';

export const JoyButton: Components<Theme>['JoyButton'] = {
  defaultProps: {
    loadingIndicator: <Spinner />,
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      borderRadius: 8,
      fontWeight: 500,
      ...(ownerState.size === 'lg' && {
        height: 48,
        fontSize: 16,
      }),
      ...(ownerState.size === 'md' && {
        height: 40,
        fontSize: 16,
      }),
      ...(ownerState.size === 'sm' && {
        height: 32,
        fontSize: 16,
      }),
      ...(ownerState.variant === 'solid' && {
        color: colors.greyishBlue,
        backgroundColor: colors.raisinBlack,
        '&:hover': {
          color: colors.greyishBlue,
          backgroundColor: colors.charcoal,
        },
        '&:active': {
          color: colors.greyishBlue,
          backgroundColor: colors.gray900,
        },
        '&:focus': {
          outlineColor: `${colors.icterine}80`,
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
        '&:disabled': {
          ...(!ownerState.loading
            ? {
                color: colors.gray600,
                backgroundColor: colors.gray300,
              }
            : {
                color: colors.charcoal,
                backgroundColor: colors.charcoal,
              }),
        },
      }),
      ...(ownerState.variant === 'outlined' && {
        backgroundColor: 'transparent',
        border: `1px solid ${colors.raisinBlack}`,
        color: colors.raisinBlack,
        '&:hover': {
          color: colors.charcoal,
          borderColor: colors.charcoal,
          backgroundColor: 'transparent',
        },
        '&:active': {
          color: colors.raisinBlack,
          borderColor: colors.raisinBlack,
          backgroundColor: 'transparent',
        },
        '&:focus': {
          outlineColor: `${colors.icterine}80`,
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
        '&:disabled': {
          borderColor: colors.spanishGray,
          color: colors.spanishGray,
          backgroundColor: 'transparent',
        },
      }),
      ...(ownerState.variant === 'plain' && {
        color: colors.raisinBlack,
        backgroundColor: 'transparent',
        '&:hover': {
          color: colors.charcoal,
          backgroundColor: 'transparent',
        },
        '&:active': {
          color: colors.raisinBlack,
          backgroundColor: 'transparent',
        },
        '&:focus': {
          outlineColor: `${colors.icterine}80`,
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
        '&:disabled': {
          borderColor: colors.spanishGray,
          color: colors.spanishGray,
          backgroundColor: 'transparent',
        },
      }),
      ...(ownerState.variant === 'secondary' && {
        color: colors.raisinBlack,
        backgroundColor: colors.icterine,
        '&:hover': {
          color: colors.raisinBlack,
          backgroundColor: '#E1E91D',
        },
        '&:active': {
          color: colors.raisinBlack,
          backgroundColor: '#E8E70D',
        },
        '&:focus': {
          outlineColor: `${colors.icterine}80`,
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
        '&:disabled': {
          borderColor: colors.gray700,
          color: colors.gray700,
          backgroundColor: '#FDFFC3',
        },
      }),
      ...(ownerState.variant === 'link' && {
        color: colors.raisinBlack,
        backgroundColor: 'transparent',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        height: 'auto',
        width: 'auto',
        textDecorationThickness: 2,
        textDecorationColor: colors.icterine,
        textDecorationStyle: 'solid',
        textDecorationLine: 'underline',

        '&:hover': {
          textDecorationThickness: 4,
        },
        '&:active': {
          textDecorationThickness: 2,
          textDecorationColor: colors.raisinBlack,
        },
        '&:focus': {
          textDecorationColor: `${colors.icterine}80`,
        },
        '&:disabled': {
          color: colors.spanishGray,
        },
      }),
    }),
    loadingIndicatorCenter: ({ ownerState }) => ({
      ...(ownerState.variant === 'solid' && {
        color: colors.greyishBlue,
      }),
    }),
  },
};

export const JoyIconButton: Components<Theme>['JoyIconButton'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.size === 'lg' && {
        width: 48,
        height: 48,
      }),
      ...(ownerState.size === 'md' && {
        width: 40,
        height: 40,
      }),
      ...(ownerState.size === 'sm' && {
        width: 32,
        height: 32,
      }),

      ...(ownerState.variant === 'ghost' && {
        backgroundColor: 'transparent',
        color: colors.raisinBlack,
        '&:hover': {
          backgroundColor: '#00000020',
        },
        '&:active': {
          backgroundColor: '#00000030',
        },
        '&:focus': {
          outlineColor: `${colors.icterine}80`,
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
        '&:disabled': {
          color: colors.spanishGray,
        },
      }),
    }),
  },
};
