import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/joy';

const VerticalScrollThumb: FC<BoxProps> = (props) => {
  return (
    <Box height="100%" bgcolor="#EEECE799" borderRadius="4px" {...props} />
  );
};

export default VerticalScrollThumb;
