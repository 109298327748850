import React, { FC, useEffect, useState } from 'react';
import {
  Stack,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  Typography,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Box,
  Modal,
  ModalDialog,
  ModalClose,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Option,
} from '@mui/joy';

import { tabClasses } from '@mui/joy/Tab';
import { useQuery } from '@tanstack/react-query';
import {
  apiGetAllOrganizations,
  apiUpdateBalance,
  apiUpdateOrgStatus,
} from '../../../apis/organizations';
import { ItemList } from '../../../components/list/OrgainaztionItemList';
import { useParams, useNavigate } from 'react-router';
import { StoreIcon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import DashboardPage from './Tab/Dashboard';
import ProfilePage from './Tab/Profile';
import BalancePage from './Tab/Balance';
import CollectionPage from './Tab/Collection';

const schema = yup
  .object({
    amount: yup.number().required(),
  })
  .required();

const updateSchema = yup
  .object({
    status: yup.string().required(),
  })
  .required();

interface IRewardCreditsData {
  amount: number;
}

interface IUpdateStatus {
  status: string;
}

const OrganizationDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [modals, setModals] = useState({
    status: false,
    update: {
      status: false,
    },
  });

  const { control, handleSubmit, setValue } = useForm<IRewardCreditsData>({
    defaultValues: {
      amount: 0,
    },
    resolver: yupResolver(schema),
  });

  const {
    control: updateControl,
    handleSubmit: updateHandleSubmit,
    setValue: updateSetValue,
  } = useForm<IUpdateStatus>({
    defaultValues: {
      status: 'active',
    },
    resolver: yupResolver(updateSchema),
  });

  const { data, refetch } = useQuery({
    queryKey: ['organizations'],
    queryFn: async () => {
      return apiGetAllOrganizations();
    },
  });

  const onProducerChange = (newId: string) => {
    navigate(`/organizations/details/${newId}`);
  };

  const handleModalClose = () => {
    setModals({
      ...modals,
      status: false,
    });
  };

  const handleModalOpen = () => {
    setModals({
      ...modals,
      status: true,
    });
  };

  const handleUpdateModalClose = () => {
    setModals({
      ...modals,
      update: {
        status: false,
      },
    });
  };

  const handleUpdateModalOpen = () => {
    setModals({
      ...modals,
      update: {
        status: true,
      },
    });
  };

  const handleUpdateReward = async (values: IRewardCreditsData) => {
    if (id) {
      await apiUpdateBalance(id, values.amount);
      handleModalClose();
      refetch();
    }
  };

  const handleUpdateStatus = async (values: IUpdateStatus) => {
    if (id) {
      await apiUpdateOrgStatus(id, values.status);
      handleUpdateModalClose();
      refetch();
    }
  };

  useEffect(() => {
    if (id) {
      const org = data?.find((item: any) => item._id == id);

      updateSetValue('status', org?.status);
    }
  }, [id]);

  return (
    <>
      <Stack>
        <Stack minHeight={'100vh'} height={'100%'} direction={'row'}>
          <Stack sx={{ backgroundColor: '#F3F1EB', paddingTop: '32px' }}>
            <ItemList
              value={id?.toString() ?? ''}
              list={data ?? []}
              onSelectChange={onProducerChange}
              label="Search"
            />
          </Stack>

          <Stack
            sx={{ paddingTop: '32px', paddingLeft: '16px' }}
            width={'100%'}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <StoreIcon />
                <Typography level="h3">
                  {data?.find((item: any) => item._id == id)?.name ?? ''}
                </Typography>
              </Stack>
              <Dropdown>
                <MenuButton variant="solid">More options</MenuButton>
                <Menu>
                  <MenuItem onClick={handleModalOpen}>Reward Credits</MenuItem>
                  <MenuItem onClick={handleUpdateModalOpen}>
                    WMO Access
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Stack>
            <Tabs
              aria-label="Pipeline"
              value={index}
              onChange={(event, value) => setIndex(value as number)}
              sx={{ backgroundColor: 'transparent' }}
            >
              <TabList
                sx={{
                  pt: 1,
                  [`&& .${tabClasses.root}`]: {
                    flex: 'initial',
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                    [`&.${tabClasses.selected}`]: {
                      color: 'primary.plainColor',
                      '&::after': {
                        height: 2,
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                        bgcolor: 'primary.500',
                      },
                    },
                  },
                }}
              >
                <Tab>Dashboard</Tab>
                <Tab>Profile</Tab>
                <Tab>Balance</Tab>
                <Tab>Collection points</Tab>
              </TabList>
              <Box>
                <TabPanel value={0}>
                  <DashboardPage />
                </TabPanel>
                <TabPanel value={1}>
                  <ProfilePage />
                </TabPanel>
                <TabPanel value={2}>
                  <BalancePage />
                </TabPanel>
                <TabPanel value={3}>
                  <CollectionPage />{' '}
                </TabPanel>
              </Box>
            </Tabs>
          </Stack>
        </Stack>
      </Stack>

      <Modal open={modals.status} onClose={() => handleModalClose()}>
        <ModalDialog minWidth={320} maxWidth={400}>
          <ModalClose />
          <DialogTitle>Reward Credits</DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              component="form"
              onSubmit={handleSubmit(handleUpdateReward)}
            >
              <Typography>
                Enter the amount of credits that you wish to award to the WMO.
              </Typography>
              <Box mt={2}>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { value } }) => (
                    <FormControl required>
                      <FormLabel>REWARDS AMOUNT</FormLabel>
                      <Input
                        value={value}
                        onChange={(e) =>
                          setValue('amount', Number(e.target.value))
                        }
                      />
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Button fullWidth type="submit">
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>

      <Modal
        open={modals.update.status}
        onClose={() => handleUpdateModalClose()}
      >
        <ModalDialog minWidth={320} maxWidth={400}>
          <ModalClose />
          <DialogTitle>WMO Access</DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              component="form"
              onSubmit={updateHandleSubmit(handleUpdateStatus)}
            >
              <Box mt={2}>
                <Controller
                  control={updateControl}
                  name="status"
                  render={({ field }) => (
                    <FormControl required>
                      <FormLabel>WMO Access</FormLabel>
                      <Select
                        value={field.value}
                        onChange={(ev, value) => field.onChange(value)}
                      >
                        <Option value="active">Active</Option>
                        <Option value="blocked">Blocked</Option>
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Button fullWidth type="submit">
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default OrganizationDetailsPage;
