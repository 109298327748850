import { Card, CardContent, Typography, Grid, Chip, Box } from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';
import {
  LineChart,
  AnimatedLine,
  AnimatedLineProps,
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';
import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts/hooks';
import { SxProps, Theme } from '@mui/system';
import { Spinner } from '../indicators/Spinner';

interface CustomAnimatedLineProps extends AnimatedLineProps {
  limit?: number;
  sxBefore?: SxProps<Theme>;
  sxAfter?: SxProps<Theme>;
}

function CustomAnimatedLine(props: CustomAnimatedLineProps) {
  const { limit, sxBefore, sxAfter, ...other } = props;
  const { top, bottom, height, left, width, right } = useDrawingArea();
  const scale = useXScale();
  const chartId = useChartId();

  if (limit === undefined) {
    return <AnimatedLine {...other} />;
  }

  const limitPosition = scale(limit); // Convert value to x coordinate.
  const endLimitPosition = scale(1) ?? 0;

  if (limitPosition === undefined) {
    return <AnimatedLine {...other} />;
  }

  const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;
  const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
  const clipIdEnd = `${chartId}-${props.ownerState.id}-line-limit-${limit}-3`;
  return (
    <React.Fragment>
      {/* Clip to show the line before the limit */}
      <clipPath id={clipIdleft}>
        <rect
          x={left}
          y={0}
          width={limitPosition - left}
          height={top + height + bottom}
        />
      </clipPath>
      {/* Clip to show the line after the limit */}
      <clipPath id={clipIdRight}>
        <rect
          x={limitPosition}
          y={0}
          width={left + width + right - limitPosition - endLimitPosition}
          height={top + height + bottom}
        />
      </clipPath>

      <clipPath id={clipIdEnd}>
        <rect
          x={width - endLimitPosition}
          y={0}
          width={endLimitPosition}
          height={top + height + bottom}
        />
      </clipPath>

      <g clipPath={`url(#${clipIdleft})`}>
        <AnimatedLine {...other} sx={sxBefore} />
      </g>
      <g clipPath={`url(#${clipIdRight})`}>
        <AnimatedLine {...other} sx={sxAfter} />
      </g>

      <g clipPath={`url(#${clipIdEnd})`}>
        <AnimatedLine {...other} sx={sxBefore} />
      </g>
    </React.Fragment>
  );
}

interface DashboardChartCardProps {
  title: string;
  total?: string;
  percent?: number;
  items?: any;
  loading?: boolean;
  description?: string;
}

const DashboardChartCard: FC<DashboardChartCardProps> = ({
  title,
  total = 0,
  percent = 0,
  items,
  loading = false,
  description = 'Last month',
}) => {
  const [data, setData] = useState<number[]>([]);
  const [xAxis, setXAxis] = useState<any[]>([]);

  useEffect(() => {
    if (items?.length > 0) {
      const tempData: any[] = [];
      const tempxAxis: any[] = [];
      items.forEach((item: any) => {
        tempData.push(item.value);
        tempxAxis.push(item.day);
      });
      setData([...tempData]);
      setXAxis([...tempxAxis]);
    }
  }, [items]);

  return (
    <Card
      sx={{
        border: 'none',
        bgcolor: 'gray100',
        minWidth: 180,
      }}
    >
      <CardContent>
        <Typography level="body-md" fontWeight={500}>
          {title}
        </Typography>
        {loading ? (
          <Box mt={1.25}>
            <Spinner color="#23222D" size={24} />
          </Box>
        ) : (
          <>
            <LineChart
              series={[
                {
                  type: 'line',
                  data: [...data],
                  showMark: ({ index }) => index === 2 || index === 7,
                },
              ]}
              xAxis={[
                {
                  data: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                  valueFormatter: (v) => `${xAxis[v]} day `,
                },
              ]}
              leftAxis={null}
              bottomAxis={null}
              height={130}
              width={250}
              margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
              slots={{ line: CustomAnimatedLine }}
              slotProps={{
                line: {
                  limit: 2,
                  sxBefore: { strokeDasharray: '1 5' },
                } as any,
              }}
              colors={['#00195A']}
              sx={{
                [`& .${lineElementClasses.root}`]: {
                  stroke: '#00195A',
                  strokeWidth: 2,
                },
                [`& .${markElementClasses.root}`]: {
                  stroke: '#BAB5B3',
                  scale: '0.8',
                  fill: '#fff',
                  strokeWidth: 2,
                },
              }}
            />
            <Typography level="h1" sx={{ position: 'absolute', top: '50px' }}>
              {total}
            </Typography>

            <Grid
              container
              spacing={1}
              sx={{
                alignItems: 'center',
                // position: 'absolute',
                // bottom: '10px',
              }}
            >
              <Grid>
                <Chip>{percent > 0 ? `+${percent}` : percent}%</Chip>
              </Grid>
              <Grid>
                <Typography level="body-sm" sx={{ color: '#9FA6AD' }}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardChartCard;
