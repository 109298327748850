import React, { FC, useState } from 'react';

import { Box, Stack, Typography, Input } from '@mui/joy';
import { SearchIcon } from 'lucide-react';

import { findString } from '../../utils/string';

interface ItemListProps {
  value: string;
  label?: string;
  list?: any;
  onSelectChange: (_id: string) => void;
}

const ItemList: FC<ItemListProps> = ({
  value,
  label = 'Search',
  list,
  onSelectChange,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const onSearchChange = (value: string) => {
    setSearchText(value);
  };

  return (
    <>
      <Stack
        minWidth={250}
        maxWidth={250}
        sx={{
          backgroundColor: '#F3F1EB',
        }}
      >
        <Box sx={{ paddingX: '20px' }} mb={5}>
          <Input
            placeholder={label}
            endDecorator={<SearchIcon />}
            sx={{
              maxWidth: 320,
            }}
            value={searchText}
            onChange={(ev) => onSearchChange(ev.currentTarget.value)}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 114px)',
            overflowY: 'auto',
          }}
        >
          {list?.map(
            (item: any, index: number) =>
              findString(searchText, Object.values(item).toString()) && (
                <Item
                  key={index}
                  onItemChange={() => onSelectChange(item._id)}
                  title={item.name}
                  content={item.email}
                  selected={value === item._id}
                />
              ),
          )}
        </Box>
      </Stack>
    </>
  );
};

interface ItemProps {
  title: string;
  content: string;
  selected: boolean;
  onItemChange: () => void;
}

const Item: FC<ItemProps> = ({ title, content, selected, onItemChange }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: selected ? '#ffffff' : '#F3F1EB',
          padding: '16px',
          paddingLeft: '32px',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: '#E3E3E3',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#F5F7F9',
            '& > *': {
              color: '#363546',
            },
          },
          '&:active': {
            backgroundColor: '#DBE6EF',
          },
        }}
        onClick={onItemChange}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Manrope',
            color: selected ? '#23222D' : '#363546E0',
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
            color: selected ? '#23222D' : '#363546E0',
            fontFamily: 'Manrope',
            fontWeight: 400,
          }}
        >
          {content}
        </Typography>
      </Box>
    </>
  );
};

export { ItemList, Item };
