import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Grid, Stack, Chip } from '@mui/joy';
import StatCard from '../../../../components/widgets/StatCard';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

import {
  apiGetCollectionInfo,
  apiGetCollectionPickup,
} from '../../../../apis/organizations';
import { useQuery } from '@tanstack/react-query';
import DataTable from '../../../../components/datatable/DataTable';

import { IPickupModel } from '../../../../apis/types';

const CollectionPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: infoData, isLoading: infoLoading } = useQuery({
    queryKey: ['collection-info', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetCollectionInfo(id);
        return data;
      }
    },
  });

  const { data: pickupData, isLoading: pickupLoading } = useQuery({
    queryKey: ['pickup', id, pagination],
    queryFn: async () => {
      if (id) {
        const data = await apiGetCollectionPickup(
          id,
          pagination.pageIndex,
          pagination.pageSize,
        );
        return data;
      }
    },
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IPickupModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.display({
        header: 'Name',
        cell: (info) => (
          <Stack>
            <Stack>
              <Stack
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                }}
              >
                {info.row.original.name}
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              gap={1}
              sx={{
                fontSize: '10px',
                fontWeight: 400,
                color: 'text.accent',
              }}
            >
              {info.row.original.official_id && (
                <Stack>OID: {info.row.original.official_id}</Stack>
              )}
              {info.row.original.id && <Stack>{info.row.original.id}</Stack>}
              {info.row.original.producer && (
                <Stack>{info.row.original.producer}</Stack>
              )}
            </Stack>
          </Stack>
        ),
      }),
      helper.display({
        header: 'Raw waste',
      }),
      helper.display({
        header: 'Balance',
      }),
      helper.display({
        header: 'Last picking',
        cell: (info) => {
          const daysAgo = info.row.original.last_picking_days;
          if (daysAgo === null || daysAgo === undefined) {
            return <>-</>;
          } else {
            return (
              <Stack direction={'row'} gap={1}>
                {info.row.original.last_picking_days === 0 ? (
                  'Today'
                ) : (
                  <>{info.row.original.last_picking_days} days ago</>
                )}
              </Stack>
            );
          }
        },
      }),
      helper.display({
        header: 'Qt',
        cell: (info) => (
          <Stack direction={'row'} gap={1}>
            {info.row.original.total_quantity}Kg
            {info.row.original.ratio !== undefined &&
            info.row.original.ratio > 0 ? (
              <span style={{ color: 'green' }}>{info.row.original.ratio}%</span>
            ) : (
              <span style={{ color: 'red' }}>{info.row.original.ratio}%</span>
            )}
          </Stack>
        ),
      }),
      helper.display({
        header: 'Next collection',
        cell: (info) => {
          if (
            info.row.original.next_picking_days === null ||
            info.row.original.next_picking_days === undefined
          )
            return <>-</>;
          else return <>In {info.row.original.next_picking_days} days</>;
        },
      }),
      helper.display({
        header: 'Frequency',
        cell: (info) => {
          if (info.row.original.frequency === null) return <>-</>;
          else return <>{info.row.original.frequency} days</>;
        },
      }),
      helper.display({
        header: 'Status',
        cell: (info) => {
          if (info.row.original.status == 'active') {
            return (
              <Chip color="success" sx={{ textTransform: 'uppercase' }}>
                {info.row.original.status}
              </Chip>
            );
          } else {
            return (
              <Chip sx={{ textTransform: 'uppercase' }}>
                {info.row.original.status}
              </Chip>
            );
          }
        },
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: pickupData?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    rowCount: pickupData?.total ?? 0,
    state: {
      pagination: pagination,
    },
    onPaginationChange: setPagination,
  });

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Fees Paid"
              value={`$${new Intl.NumberFormat().format(infoData?.fees_paid ?? 0)}`}
              variant="dark"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Remaining Credits"
              value={`${new Intl.NumberFormat().format(infoData?.remaining_credits ?? 0)}`}
              variant="light-blue"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Credits on Hold"
              value={`${new Intl.NumberFormat().format(infoData?.credits_hold ?? 0)}`}
              variant="light-blue"
              loading={infoLoading}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <DataTable table={table} loading={pickupLoading} />
      </Box>
    </>
  );
};

export default CollectionPage;
