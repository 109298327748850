import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import React from 'react';

interface IConfirmationDialogProps {
  open: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
  positiveButtonText?: string;
  positiveButtonIcon?: React.ReactNode;
  negativeButtonText?: string;
  negativeButtonIcon?: React.ReactNode;
  onPositive: () => void;
  onNegative: () => void;
  onClose?: () => void;
}

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  open,
  title,
  description,
  positiveButtonText,
  positiveButtonIcon,
  negativeButtonText,
  negativeButtonIcon,
  onPositive,
  onNegative,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose ? onClose : onNegative}>
      <ModalDialog minWidth={320} maxWidth={480}>
        <ModalClose />
        {typeof title === 'string' ? <DialogTitle>{title}</DialogTitle> : title}
        {typeof description === 'string' ? (
          <DialogContent>
            <Typography component="p">{description}</Typography>
          </DialogContent>
        ) : (
          description
        )}
        <Box mt={2}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Button
              variant="outlined"
              startDecorator={negativeButtonIcon ? negativeButtonIcon : null}
              onClick={onNegative}
            >
              {negativeButtonText ? negativeButtonText : 'Cancel'}
            </Button>
            <Button
              startDecorator={positiveButtonIcon ? positiveButtonIcon : null}
              onClick={onPositive}
            >
              {positiveButtonText ? positiveButtonText : 'Confirm'}
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationDialog;
