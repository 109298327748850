import { Components, Theme } from '@mui/joy';
import { colors } from '../theme';

export const JoyModalDialog: Components<Theme>['JoyModalDialog'] = {
  styleOverrides: {
    root: () => ({
      backgroundColor: colors.white,
    }),
  },
};
