import React, { FC, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Input,
  Button,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
} from '@mui/joy';
import { StoreIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router';

import StatCard from '../../../components/widgets/StatCard';
import {
  apiGetInfoOrganizationById,
  apiUpdateBalance,
} from '../../../apis/organizations';

interface IRewardCreditsData {
  amount: number;
}

const schema = yup
  .object({
    amount: yup.number().required(),
  })
  .required();

const OrganizationInfoPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [modals, setModals] = useState({
    status: false,
  });
  const { control, handleSubmit, setValue } = useForm<IRewardCreditsData>({
    defaultValues: {
      amount: 0,
    },
    resolver: yupResolver(schema),
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['organization', 'info'],
    queryFn: async () => {
      if (id) {
        const data = await apiGetInfoOrganizationById(id);
        setValue('amount', Number(data?.available_balance));
        return data;
      }
    },
  });

  const handleModalClose = () => {
    setModals({
      status: false,
    });
  };

  const handleModalOpen = () => {
    setModals({
      status: true,
    });
  };

  const handleUpdateReward = async (values: IRewardCreditsData) => {
    await apiUpdateBalance(data?._id, values.amount);
    handleModalClose();
    refetch();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <StoreIcon />
          <Typography level="h3">{data?.name ?? ''}</Typography>
        </Stack>
        <Dropdown>
          <MenuButton variant="solid">Actions</MenuButton>
          <Menu>
            <MenuItem onClick={handleModalOpen}>Reward Credits</MenuItem>
          </Menu>
        </Dropdown>
      </Stack>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Nº Collection Points"
              value={data?.count_of_pickup_points ?? 0}
              variant="dark"
              loading={isLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Fees Collected"
              value={`$${new Intl.NumberFormat().format(data?.amount_fees ?? 0)}`}
              variant="light-blue"
              loading={isLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Remaining Credits"
              value={data?.available_balance ?? 0}
              variant="light-blue"
              loading={isLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Team members"
              value={data?.count_member ?? 0}
              variant="light"
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>

      <Modal open={modals.status} onClose={() => handleModalClose()}>
        <ModalDialog minWidth={320} maxWidth={400}>
          <ModalClose />
          <DialogTitle>Reward Credits</DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              component="form"
              onSubmit={handleSubmit(handleUpdateReward)}
            >
              <Typography>
                Enter the amount of credits that you wish to award to the WMO.
              </Typography>
              <Box mt={2}>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { value } }) => (
                    <FormControl required>
                      <FormLabel>REWARDS AMOUNT</FormLabel>
                      <Input
                        value={value}
                        onChange={(e) =>
                          setValue('amount', Number(e.target.value))
                        }
                      />
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Button fullWidth type="submit">
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default OrganizationInfoPage;
