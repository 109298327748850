import React from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import AuthLayout from '../components/layouts/AuthLayout';
import MainLayout from '../components/layouts/MainLayout';

import SignInPage from '../pages/auth/SignInPage';
import SignOutPage from '../pages/auth/SignOutPage';

import DashboardPage from '../pages/dashboard/DashboardPage';
import UsersPage from '../pages/users/UsersPage';
import OrganizationsPage from '../pages/organizations/OrganizationsPage';
import OrganizationInfoPage from '../pages/organizations/organizationInfo/OrganizationInfoPage';
import OrganizationDetailsPage from '../pages/organizations/organizationDetails/OrganizationDetailsPage';
import PrivacyPolicyPage from '../pages/settings/PrivacyPolicyPage';
import CookiePolicyPage from '../pages/settings/CookiePolicyPage';
import TermsPage from '../pages/settings/TermsPage';
import BillingPage from '../pages/billing/BillingPage';

export const routes = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'signin',
        element: <SignInPage />,
      },
      {
        path: 'signout',
        element: <SignOutPage />,
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'users',
        element: <UsersPage />,
      },
      {
        path: 'organizations',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <OrganizationsPage />,
          },
          {
            path: 'info/:id',
            element: <OrganizationInfoPage />,
          },
          {
            path: 'details/:id',
            element: <OrganizationDetailsPage />,
          },
        ],
      },
      {
        path: 'billing',
        element: <BillingPage />,
      },
      {
        path: 'settings/privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'settings/cookie-policy',
        element: <CookiePolicyPage />,
      },
      {
        path: 'settings/terms',
        element: <TermsPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]);
