import { extendTheme } from '@mui/joy';

import { JoyBox } from './themes/JoyBox';
import { JoyButton, JoyIconButton } from './themes/JoyButton';
import { JoyFormHelperText, JoyFormLabel, JoyInput } from './themes/JoyInput';
import { JoyCard } from './themes/JoyCard';
import { JoyCheckbox } from './themes/JoyCheckbox';
import { JoyTypography } from './themes/JoyTypography';
import { JoyChip } from './themes/JoyChip';
import { JoySelect } from './themes/JoySelect';
import { JoyModalDialog } from './themes/JoyModalDialog';

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  gray100: '#FCFCFC',
  gray200: '#F8F8F8',
  gray300: '#F0F0F0',
  gray400: '#DBDBDB',
  gray500: '#C9C9C9',
  gray600: '#AFAFAF',
  gray700: '#6D6D6D',
  gray800: '#282828',
  gray900: '#131313',
  royalBlueDark: '#00195A',
  purpleNavy: '#475985',
  shadowBlue: '#7783A1',
  beauBlue: '#B4C7D6',
  greyishBlueDark: '#6293BC',
  greyishBlue: '#C0D4E4',
  greyishBlueLight: '#E1ECF4',
  greyishBlueLighter: '#EFF5F9',
  greyishBlueLightest: '#F8FAFC',
  icterine: '#EBF155',
  isabellineLight: '#F7F6F3',
  isabelline: '#EEECE7',
  isabellineDarker: '#CCCAC5',
  spanishGray: '#9F9E9E',
  charcoal: '#464A53',
  raisinBlack: '#23222D',
  red: '#D64C56',
  green: '#2CB53B',
};

export const theme = extendTheme({
  fontFamily: {
    body: 'Manrope',
    display: 'Manrope',
    code: 'consolas',
    fallback: 'sans-serif',
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '500': colors.raisinBlack,
        },
        danger: {
          '500': colors.red,
        },
        success: {
          '500': colors.green,
        },
        mode: 'light',
        common: {
          black: colors.black,
          white: colors.white,
        },
        divider: `${colors.greyishBlue}1A`,
        ...colors,
      },
    },
  },
  radius: {
    md: '8px',
  },

  components: {
    JoyBox: JoyBox,
    JoyButton: JoyButton,
    JoyIconButton: JoyIconButton,
    JoyInput: JoyInput,
    JoyFormLabel: JoyFormLabel,
    JoyFormHelperText: JoyFormHelperText,
    JoyCard: JoyCard,
    JoyCheckbox: JoyCheckbox,
    JoyTypography: JoyTypography,
    JoyChip: JoyChip,
    JoySelect: JoySelect,
    JoyModalDialog: JoyModalDialog,
  },
});
