import React, { FC, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/joy';
import StatCard from '../../../../components/widgets/StatCard';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import DataTable from '../../../../components/datatable/DataTable';
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

import {
  apiGetBalanceInfo,
  apiGetBalanceTransactions,
} from '../../../../apis/organizations';

import { ITransactionsModel } from '../../../../apis/types';

const BalancePage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: infoData, isLoading: infoLoading } = useQuery({
    queryKey: ['balance-info', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetBalanceInfo(id);
        return data;
      }
    },
  });

  const { data: transactionData, isLoading: transactionLoading } = useQuery({
    queryKey: ['transaction', id, pagination],
    queryFn: async () => {
      if (id) {
        const data = await apiGetBalanceTransactions(
          id,
          pagination.pageIndex,
          pagination.pageSize,
        );
        return data;
      }
    },
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<ITransactionsModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('created_at', {
        header: 'Date',
        cell: (info) => new Date(info.getValue() ?? '').toLocaleDateString(),
      }),
      helper.accessor('action', {
        header: 'Transaction Type',
        cell: (info) => info.getValue()?.toUpperCase(),
      }),
      helper.accessor('amount', {
        header: 'Amount',
        cell: (info) => {
          if (Number(info.getValue()) > 0) {
            return (
              <span
                style={{
                  color: '#005609',
                }}
              >
                +{info.getValue()?.toLocaleString('en-US')}
              </span>
            );
          } else {
            return (
              <span
                style={{
                  color: '#A60814',
                }}
              >
                -{info.getValue()?.toLocaleString('en-US')}
              </span>
            );
          }
        },
      }),
      helper.accessor('_id', {
        header: 'Transaction ID',
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: transactionData?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    rowCount: transactionData?.total ?? 0,
    state: {
      pagination: pagination,
    },
    onPaginationChange: setPagination,
  });

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Fees Paid"
              value={`$${new Intl.NumberFormat().format(infoData?.fees_paid ?? 0)}`}
              variant="dark"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Remaining Credits"
              value={`${new Intl.NumberFormat().format(infoData?.remaining_credits ?? 0)}`}
              variant="light-blue"
              loading={infoLoading}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Credits on Hold"
              value={`${new Intl.NumberFormat().format(infoData?.credits_hold ?? 0)}`}
              variant="light-blue"
              loading={infoLoading}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <DataTable table={table} loading={transactionLoading} />
      </Box>
    </>
  );
};

export default BalancePage;
