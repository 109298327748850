import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IConstantsModel } from './types';

export const apiGetConstants = async () => {
  try {
    const response = await axios.get('/api/constants');
    return response.data as Partial<IConstantsModel>[];
  } catch (e) {
    enqueueSnackbar('Failed to load billing data', { variant: 'error' });
    return null;
  }
};

export const apiUpdateConstants = async (data: Partial<IConstantsModel>) => {
  try {
    await axios.put('/api/constants', data);
    enqueueSnackbar('Successfully updated the billing settings', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update billing data', { variant: 'error' });
    return false;
  }
};
