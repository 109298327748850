import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { resetAppState, setAppState } from '../../redux/app.slice';

export const CustomSnackbarProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(
      setAppState({
        enqueueSnackbar: enqueueSnackbar,
        closeSnackbar: closeSnackbar,
      }),
    );
    return () => {
      dispatch(resetAppState());
    };
  }, []);

  return children;
};
