import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IUserModel } from './types';

export const apiGetUsers = async ({
  keyword = '',
  pageIndex,
  pageSize,
  sortBy,
  sortOrder,
}: {
  keyword?: string | null;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
}) => {
  try {
    const response = await axios.post<{
      total_count: number;
      data: IUserModel[];
    }>('/api/users', {
      page: pageIndex,
      limit: pageSize,
      ...(sortBy &&
        sortOrder && {
          sort: [{ field: sortBy, order: sortOrder }],
        }),
      ...(keyword && {
        keyword,
      }),
    });

    return {
      rows: response.data.data,
      total: response.data.total_count,
      stats: {
        total: response.data.total_count,
        pending: 0,
        withoutMfa: 0,
      },
    };
  } catch (e) {
    enqueueSnackbar('Failed to load users data', { variant: 'error' });
    return {
      total: 0,
      rows: [],
    };
  }
};

export const apiUpdateUserStatus = async (id: string, status: string) => {
  try {
    await axios.put(`/api/users/${id}`, {
      status,
    });
    enqueueSnackbar('Successfully updated the selected user', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiDeleteUser = async (id: string) => {
  try {
    await axios.delete(`/api/users/${id}`);
    enqueueSnackbar('Successfully deleted the selected user', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to delete the selected users', {
      variant: 'error',
    });
    return false;
  }
};
