import { Components, Theme } from '@mui/joy';

export const JoyTypography: Components<Theme>['JoyTypography'] = {
  defaultProps: {
    textColor: 'raisinBlack',
  },
  styleOverrides: {
    root: {},
  },
};
