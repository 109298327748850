import React, { useMemo } from 'react';
import { Box } from '@mui/joy';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppSideBar from './main-layout/AppSideBar';
import AppContent from './main-layout/AppContent';
import { IRootState } from '../../redux/store';

const MainLayout: React.FC<React.PropsWithChildren> = () => {
  const location = useLocation();
  const auth = useSelector((state: IRootState) => state.auth);

  const isAuthenticated = useMemo(() => {
    return !!auth.accessToken;
  }, [auth]);

  if (isAuthenticated) {
    return (
      <Box>
        <AppSideBar />
        <AppContent>
          <Outlet />
        </AppContent>
      </Box>
    );
  }

  return <Navigate to={`/auth/signin?to=${location.pathname}`} replace />;
};

export default MainLayout;
