import React from 'react';
import { Components, Theme } from '@mui/joy';
import { ChevronDownIcon } from 'lucide-react';
import { colors } from '../theme';

export const JoySelect: Components<Theme>['JoySelect'] = {
  defaultProps: {
    indicator: <ChevronDownIcon size={16} color="#CCCAC5" />,
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      backgroundColor: colors.white,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#EEECE7',
      color: colors.raisinBlack,
      lineHeight: '20px',
      boxShadow: 'none',

      ...(ownerState.size === 'lg' && {
        height: 48,
        fontSize: 14,
      }),
      ...(ownerState.size === 'md' && {
        height: 40,
        fontSize: 14,
        padding: '0 16px',
      }),
      ...(ownerState.size === 'sm' && {
        height: 32,
        fontSize: 14,
      }),

      '&:hover': {
        borderColor: '#CCCAC5',
        backgroundColor: colors.white,
      },
      '&:focus, &.Mui-focused, &.Mui-expanded': {
        borderWidth: 1,
        borderColor: '#9F9E9E',
        outlineColor: `${colors.icterine}80`,
        outlineWidth: 2,
        outlineStyle: 'solid',
      },
      '&:active': {
        borderWidth: 1,
        borderColor: '#9F9E9E',
        outlineColor: `${colors.icterine}80`,
        outlineWidth: 2,
        outlineStyle: 'solid',
      },
      '&::before': {
        content: '""',
        display: 'none',
      },

      '.Mui-error &': {
        backgroundColor: '#D64C5612',
      },
      '&.Mui-error': {
        backgroundColor: '#D64C5612',
      },

      '& .MuiInput-endDecorator, &.MuiInput-startDecorator': {
        color: '#7783A1',
      },
    }),
    listbox: () => ({
      '--ListItem-minHeight': '40px',

      background: colors.white,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.gray400,
      boxShadow: '0px 4px 8px 0px rgba(35, 34, 45, 0.15)',
      paddingBlock: 0,

      '& .MuiOption-root': {
        fontSize: 14,
        fontWeight: 500,
        color: colors.raisinBlack,
        backgroundColor: 'transparent',
        '&:not(:first-of-type)': {
          borderTopWidth: 1,
          borderTopColor: colors.gray300,
          borderTopStyle: 'solid',
        },

        '&.MuiOption-highlighted': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
          backgroundColor: `${colors.icterine}3A`,
        },
        '&:hover': {
          backgroundColor: `${colors.icterine}3A`,
        },
      },
    }),
  },
};
