import React, { FC, useState } from 'react';
import {
  Box,
  Card,
  Stack,
  CardContent,
  Typography,
  Input,
  Button,
} from '@mui/joy';
import { LandmarkIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { apiGetConstants, apiUpdateConstants } from '../../apis/constants';
import { Controller, useForm } from 'react-hook-form';
import { IConstantsModel } from '../../apis/types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  credit_per_kg: yup.number().required(),
  cost_per_credit: yup.number().required(),
});

const BillingPage: FC = () => {
  const { control, setValue, handleSubmit } = useForm<IConstantsModel>({
    defaultValues: {
      credit_per_kg: 0,
      cost_per_credit: 0,
    },
    resolver: yupResolver(schema),
  });
  const [processing, setProcessing] = useState(false);

  useQuery({
    queryKey: ['constants', 'billing'],
    queryFn: async () => {
      const data = await apiGetConstants();
      if (data && data.length > 0) {
        setValue('credit_per_kg', Number(data[0].credit_per_kg));
        setValue('cost_per_credit', Number(data[0].cost_per_credit));
        return data;
      } else {
        return [];
      }
    },
  });

  const onSubmit = async (values: IConstantsModel) => {
    if (values) {
      setProcessing(true);
      await apiUpdateConstants(values);
      setProcessing(false);
    }
  };

  return (
    <Box
      sx={{ paddingLeft: '32px', paddingTop: '32px', paddingBottom: '32px' }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <LandmarkIcon />
          <Typography level="h3">Billing</Typography>
        </Stack>
        <Button loading={processing} onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
      </Stack>
      <Box mt={4}>
        <Card>
          <CardContent sx={{ minHeight: 100 }}>
            <Stack direction="column" gap={4} sx={{ maxWidth: 350 }}>
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Credit Per KG</Typography>
                <Controller
                  name="credit_per_kg"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      title="Credit per KG"
                      placeholder="Credit per KG"
                      value={value}
                      onChange={(e) => onChange(e)}
                    />
                  )}
                />
              </Stack>

              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Cost Per Credit</Typography>
                <Controller
                  name="cost_per_credit"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      title="Cost per Credit"
                      placeholder="Cost per Credit"
                      value={value}
                      onChange={(e) => onChange(e)}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default BillingPage;
