import React, { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { apiSignOut } from '../../apis/auth';

const SignOutPage: FC = () => {
  useEffect(() => {
    apiSignOut();
  }, []);

  return <Navigate to="/auth/signin" />;
};

export default SignOutPage;
