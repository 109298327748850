import React, { FC } from 'react';
import { Table, flexRender } from '@tanstack/react-table';
import { Box, IconButton, Stack, Typography, styled } from '@mui/joy';
import { colors } from '../../configs/theme';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SortDisabledIcon,
} from './Icons';
import { Spinner } from '../indicators/Spinner';
import Pagination from './Pagination';

interface IDataTableProps {
  table: Table<any>;
  loading?: boolean;
  onRowClick?: (row: any) => void | undefined;
  pagination?: boolean;
}

const TableHead = styled('thead')`
  height: 40px;
  text-align: left;

  & th {
    box-sizing: border-box;
    padding: 0 16px;
    vertical-align: middle !important;
    color: ${colors.gray700};
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }
`;

const TableBody = styled('tbody')`
  position: relative;
  & tr {
    & td {
      background-color: #ffffff;
      border-bottom: 1px solid ${colors.gray300};
    }
    &:first-of-type {
      & td:first-of-type {
        border-top-left-radius: 8px;
      }
      & td:last-of-type {
        border-top-right-radius: 8px;
      }
    }
    &:last-of-type {
      & td:first-of-type {
        border-bottom-left-radius: 8px;
      }
      & td:last-of-type {
        border-bottom-right-radius: 8px;
      }
    }
    &:hover td {
      background-color: #f8fafc;
    }
  }
  & td {
    height: auto;
    padding: 8px 16px;
    overflow: hidden;
    font-size: 14px;
    border-spacing: 0px;
    border: none;
  }
`;

const DataTable: FC<IDataTableProps> = ({
  table,
  loading,
  onRowClick,
  pagination = true,
}) => {
  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          overflowX: 'auto',
        }}
      >
        <table
          style={{
            width: '100%',
            borderSpacing: 0,
          }}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      minWidth: header.column.columnDef.minSize
                        ? header.column.columnDef.minSize
                        : 80,
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanSort() ? (
                        <IconButton
                          sx={{
                            ml: 0.5,
                            width: 24,
                            height: 24,
                            minWidth: 0,
                            minHeight: 0,
                            padding: 0,
                            pb: 0.25,
                          }}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {{
                            asc: <SortAscendingIcon />,
                            desc: <SortDescendingIcon />,
                          }[header.column.getIsSorted() as string] ?? (
                            <SortDisabledIcon />
                          )}
                        </IconButton>
                      ) : (
                        <span></span>
                      )}
                    </Stack>
                  </th>
                ))}
              </tr>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  onClick={() => onRowClick && onRowClick(row.original._id)}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        minWidth: cell.column.columnDef.minSize
                          ? cell.column.columnDef.minSize
                          : 80,
                      }}
                      onClick={(e) => {
                        if (cell.column.id === 'actions') {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  <Box
                    borderRadius="md"
                    bgcolor="white"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={200}
                  >
                    <Typography textColor="spanishGray">
                      No data available
                    </Typography>
                  </Box>
                </td>
              </tr>
            )}
            {loading ? (
              <Box
                component="tr"
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  borderRadius: 'md',
                  backgroundColor: `${colors.raisinBlack}1A`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  borderRadius="md"
                  bgcolor="white"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                  spacing={1}
                  component="td"
                >
                  <Spinner />
                  <Typography>Loading</Typography>
                </Stack>
              </Box>
            ) : null}
          </TableBody>
        </table>
      </Box>
      {pagination ? (
        <Box mt={1}>
          <Pagination
            pageIndex={table.getState().pagination.pageIndex}
            pageSize={table.getState().pagination.pageSize}
            totalRows={table.getRowCount()}
            totalPages={table.getPageCount()}
            onChangePageSize={table.setPageSize}
            onChangePageIndex={table.setPageIndex}
            onFirstPage={table.firstPage}
            onLastPage={table.lastPage}
            onNextPage={table.nextPage}
            onPreviousPage={table.previousPage}
          />
        </Box>
      ) : null}
    </>
  );
};

export default DataTable;
