import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from '@mui/joy';
import { LockIcon, MailIcon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { ISignInRequest } from '../../apis/types';
import { apiSignIn } from '../../apis/auth';

const schema = yup
  .object({
    email: yup
      .string()
      .email('Must be valid email address')
      .required('This is a required field'),
    password: yup.string().required('This is a required field'),
    remember_me: yup.boolean().required(),
  })
  .required();

const SignInPage: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<ISignInRequest>({
    defaultValues: {
      email: '',
      password: '',
      remember_me: true,
    },
    resolver: yupResolver(schema),
  });

  const handleSignIn = async (values: ISignInRequest) => {
    setLoading(true);
    if (await apiSignIn(values)) {
      navigate('/dashboard');
    }
    setLoading(false);
  };

  return (
    <Card sx={{ width: 320 }}>
      <CardContent>
        <form onSubmit={handleSubmit(handleSignIn)}>
          <Typography level="h2">Sign in</Typography>
          <Typography>Welcome back! Log in with your email</Typography>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <Box mt={2}>
                <FormControl required error={!!error?.message}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Insert your email"
                    endDecorator={<MailIcon size={16} />}
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>{error?.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState: { error } }) => (
              <Box mt={2}>
                <FormControl required error={!!error?.message}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Insert your password"
                    endDecorator={<LockIcon size={16} />}
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>{error?.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="remember_me"
            render={({ field: { value, onChange } }) => (
              <Box mt={2}>
                <Checkbox
                  label="Remember me"
                  checked={value}
                  onChange={onChange}
                />
              </Box>
            )}
          />
          <Box mt={2}>
            <Button fullWidth loading={loading} type="submit">
              Sign In
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default SignInPage;
