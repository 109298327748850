import axios from 'axios';
// import { enqueueSnackbar } from 'notistack';

export const apiGetWMOChart = async () => {
  try {
    const response = await axios.get('/api/dashboard/wmo-chart');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetProducerChart = async () => {
  try {
    const response = await axios.get('/api/dashboard/producer-chart');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetWasteChart = async () => {
  try {
    const response = await axios.get('/api/dashboard/waste-chart');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetFeeChart = async () => {
  try {
    const response = await axios.get('/api/dashboard/fee-chart');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetTopWMOList = async () => {
  try {
    const response = await axios.get('/api/dashboard/top-wmo');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetTopCountriesChart = async () => {
  try {
    const response = await axios.get('/api/dashboard/top-countries-chart');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetTotalRechargesChart = async ({ type }: { type: string }) => {
  try {
    const response = await axios.post('/api/dashboard/total-recharges-chart', {
      type,
    });
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetFeesVsCreditChart = async () => {
  try {
    const response = await axios.get('/api/dashboard/fees-credit-chart');
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetWasteCollectedChart = async ({ type }: { type: string }) => {
  try {
    const response = await axios.post('/api/dashboard/waste-over-chart', {
      type,
    });
    return response.data;
  } catch (e) {
    return {};
  }
};

export const apiGetFeesCollectedChart = async ({ type }: { type: string }) => {
  try {
    const response = await axios.post('/api/dashboard/fees-over-chart', {
      type,
    });
    return response.data;
  } catch (e) {
    return {};
  }
};
