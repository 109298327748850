import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IPolicyModel } from './types';

export const apiGetPolicies = async () => {
  try {
    const response = await axios.get('/api/policies');
    return response.data as Partial<IPolicyModel>[];
  } catch (e) {
    enqueueSnackbar('Failed to load policies data', { variant: 'error' });
    return [];
  }
};

export const apiGetPolicy = async (id: string) => {
  try {
    const response = await axios.get('/api/policies/' + id);
    return response.data as Partial<IPolicyModel>;
  } catch (e) {
    enqueueSnackbar('Failed to load policies data', { variant: 'error' });
    return null;
  }
};

export const apiUpdatePolicy = async (data: Partial<IPolicyModel>) => {
  try {
    if (data._id) {
      await axios.put('/api/policies/' + data._id, {
        name: data.name,
        content: data.content,
      });
    } else {
      await axios.post('/api/policies', {
        name: data.name,
        content: data.content,
      });
    }
    enqueueSnackbar('Successfully updated the policy content', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update policy data', { variant: 'error' });
    return false;
  }
};
