import { SortingState } from '@tanstack/react-table';

export function getSortingStateFromQuery(
  params: URLSearchParams,
  fields: string[],
) {
  const sorting: SortingState = [];
  const query = params.get('sortBy');
  if (query) {
    const arr = query.split(',');
    if (arr.length === 2) {
      if (!fields.includes(arr[0])) {
        return sorting;
      }
      if (!['asc', 'desc'].includes(arr[1])) {
        return sorting;
      }
      sorting.push({
        id: arr[0],
        desc: arr[1] === 'desc',
      });
    }
  }
  return sorting;
}
