import React, { FC, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Menu,
  Stack,
  Tooltip,
  Typography,
  Dropdown,
  styled,
  MenuButton,
} from '@mui/joy';
import { ClickAwayListener } from '@mui/base';
import {
  ChevronRightIcon,
  LogOutIcon,
  PieChartIcon,
  SettingsIcon,
  StoreIcon,
  UserIcon,
  UsersIcon,
  LandmarkIcon,
} from 'lucide-react';
import Scrollbars from 'react-custom-scrollbars-2';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import VerticalScrollThumb from '../../indicators/VerticalScrollThumb';
import { colors } from '../../../configs/theme';
import { IRootState } from '../../../redux/store';
import img1 from '../../../assets/logos/light-full-logo.png';
import img2 from '../../../assets/logos/light-small-logo.png';

const LogoBox = styled(NavLink)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 24,

  ...(props.role === 'shrink' && {
    justifyContent: 'center',
  }),
}));

const AppSidebarList = styled(List)({
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'visible',

  '& a': {
    textDecoration: 'none !important',
  },
  '& .MuiListItem-root': {
    height: 44,
    '& .MuiListItemDecorator-root': {
      color: colors.greyishBlue,
    },
    '& .MuiListItemButton-root': {
      paddingLeft: 20,
      gap: 8,
      color: 'white',
      '&:hover': {
        backgroundColor: `${colors.greyishBlue}1A`,
      },
    },
    '& .MuiListItemContent-root': {
      color: 'white',
      fontSize: 16,
      fontWeight: 500,
      whiteSpace: 'nowrap !important',
    },

    '&.MuiListItem-nested': {
      height: 32,
      '& .MuiListSubheader-root': {
        marginTop: 12,
        paddingLeft: 20,
        minBlockSize: 'auto',
        color: `${colors.greyishBlue}80`,
      },
    },
  },
  '& .active .MuiListItemDecorator-root': {
    color: colors.icterine,
  },
  '& .active .MuiListItemContent-root': {
    color: colors.icterine,
  },
  '& .active .MuiListItemButton-root': {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 8.5,
      width: 4,
      height: 24,
      backgroundColor: colors.icterine,
    },
    '&:hover': {
      backgroundColor: `${colors.icterine}1A`,
    },
  },
  '& .MuiAccordionSummary-root': {
    height: 44,
  },
  '& .MuiAccordionSummary-button:hover': {
    backgroundColor: `${colors.greyishBlue}1A !important`,
    '& .MuiListItemButton-root': {
      backgroundColor: 'unset',
    },
  },
  '& .MuiAccordionSummary-button:active': {
    backgroundColor: `${colors.greyishBlue}2A !important`,
    '& .MuiListItemButton-root': {
      backgroundColor: 'unset',
    },
  },
  '& .MuiAccordionDetails-content': {
    '& .MuiListItemButton-root': {
      paddingLeft: 32,
    },
  },
  '&.shrink .MuiListItemContent-root': {
    display: 'none',
  },
  '&.shrink .MuiListItemButton-root': {
    justifyContent: 'center',
  },
});

const AppSideBar: FC = () => {
  const auth = useSelector((state: IRootState) => state.auth);
  const isSm = useMediaQuery({ query: '(max-width: 1224px)' });

  const [anchor, setAnchor] = useState<any>();

  const handleOpen = (ev: any) => {
    setAnchor(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <Stack
      width={isSm ? 80 : 240}
      height="100vh"
      bgcolor="raisinBlack"
      position="fixed"
      left={0}
      top={0}
      sx={{
        overflowX: 'hidden',
        overflowY: 'visible',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <LogoBox to="/dashboard" role={isSm ? 'shrink' : ''}>
        {isSm ? (
          <img src={img2} alt="UCO Admin Portal" width={40} height={40} />
        ) : (
          <img src={img1} alt="UCO Admin Portal" width={132} height={40} />
        )}
      </LogoBox>
      <Scrollbars renderThumbVertical={VerticalScrollThumb}>
        <AppSidebarList className={isSm ? 'shrink' : 'expanded'}>
          <Tooltip title={isSm ? 'Dashboard' : ''} placement="right">
            <NavLink to="/dashboard">
              <ListItem>
                <ListItemButton>
                  <ListItemDecorator>
                    <PieChartIcon />
                  </ListItemDecorator>
                  <ListItemContent>Dashboard</ListItemContent>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </Tooltip>
          <Tooltip title={isSm ? 'Users' : ''} placement="right">
            <NavLink to="/users">
              <ListItem>
                <ListItemButton>
                  <ListItemDecorator>
                    <UsersIcon />
                  </ListItemDecorator>
                  <ListItemContent>Users</ListItemContent>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </Tooltip>
          <Tooltip title={isSm ? 'Organizations' : ''} placement="right">
            <NavLink to="/organizations">
              <ListItem>
                <ListItemButton>
                  <ListItemDecorator>
                    <StoreIcon />
                  </ListItemDecorator>
                  <ListItemContent>Organizations</ListItemContent>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </Tooltip>
          <Tooltip title={isSm ? 'Billing' : ''} placement="right">
            <NavLink to="/billing">
              <ListItem>
                <ListItemButton>
                  <ListItemDecorator>
                    <LandmarkIcon />
                  </ListItemDecorator>
                  <ListItemContent>Billing</ListItemContent>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </Tooltip>
          {isSm ? (
            <>
              <Tooltip title={isSm ? 'Settings' : ''} placement="right">
                <ListItem>
                  <ListItemButton onClick={handleOpen}>
                    <ListItemDecorator>
                      <SettingsIcon />
                    </ListItemDecorator>
                    <ListItemContent>Settings</ListItemContent>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
              <Menu
                anchorEl={anchor}
                open={!!anchor}
                placement="right-start"
                sx={{
                  '& .MuiListItemButton-root': {
                    gap: 0,
                  },
                  '& a': {
                    textDecoration: 'none',
                  },
                }}
                onClick={handleClose}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <NavLink to="/settings/privacy-policy">
                      <ListItem>
                        <ListItemButton>
                          <ListItemDecorator>
                            <ChevronRightIcon />
                          </ListItemDecorator>
                          <ListItemContent>Privacy Policy</ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                    <NavLink to="/settings/cookie-policy">
                      <ListItem>
                        <ListItemButton>
                          <ListItemDecorator>
                            <ChevronRightIcon />
                          </ListItemDecorator>
                          <ListItemContent>Cookie Policy</ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                    <NavLink to="/settings/terms">
                      <ListItem>
                        <ListItemButton>
                          <ListItemDecorator>
                            <ChevronRightIcon />
                          </ListItemDecorator>
                          <ListItemContent>Terms & Conditions</ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                  </Box>
                </ClickAwayListener>
              </Menu>
            </>
          ) : (
            <AccordionGroup>
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <ListItem>
                    <ListItemButton>
                      <ListItemDecorator>
                        <SettingsIcon />
                      </ListItemDecorator>
                      <ListItemContent>Settings</ListItemContent>
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                  <NavLink to="/settings/privacy-policy">
                    <ListItem>
                      <ListItemButton>
                        <ListItemDecorator>
                          <ChevronRightIcon />
                        </ListItemDecorator>
                        <ListItemContent>Privacy Policy</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  <NavLink to="/settings/cookie-policy">
                    <ListItem>
                      <ListItemButton>
                        <ListItemDecorator>
                          <ChevronRightIcon />
                        </ListItemDecorator>
                        <ListItemContent>Cookie Policy</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  <NavLink to="/settings/terms">
                    <ListItem>
                      <ListItemButton>
                        <ListItemDecorator>
                          <ChevronRightIcon />
                        </ListItemDecorator>
                        <ListItemContent>Terms & Conditions</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          )}
        </AppSidebarList>
      </Scrollbars>
      <Divider />
      <Box px={3} pt={1.5} pb={isSm ? 1.5 : 0}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          {isSm ? (
            <Dropdown>
              <MenuButton
                variant="plain"
                sx={{
                  p: 0,
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                <Avatar
                  size="sm"
                  sx={{
                    bgcolor: 'greyishBlue',
                  }}
                >
                  <UserIcon size={20} />
                </Avatar>
              </MenuButton>
              <Menu>
                <ListItem>
                  <ListItemButton
                    component={NavLink}
                    to="/auth/signout"
                    replace
                  >
                    <ListItemDecorator>
                      <LogOutIcon />
                    </ListItemDecorator>
                    <ListItemContent>Logout</ListItemContent>
                  </ListItemButton>
                </ListItem>
              </Menu>
            </Dropdown>
          ) : (
            <Avatar
              size="sm"
              sx={{
                bgcolor: 'greyishBlue',
              }}
            >
              <UserIcon size={20} />
            </Avatar>
          )}
          <Typography
            textColor="greyishBlue"
            level="body-sm"
            display={isSm ? 'none' : 'block'}
          >
            {auth.name || 'Admin'}
          </Typography>
          <IconButton
            variant="ghost"
            size="sm"
            component={NavLink}
            to="/auth/signout"
            sx={
              isSm
                ? {
                    display: 'none',
                  }
                : {}
            }
          >
            <LogOutIcon color={colors.beauBlue} size={20} />
          </IconButton>
        </Stack>
      </Box>
      <Typography
        component="div"
        px={3}
        py={1.5}
        level="body-xs"
        textColor="greyishBlue"
        display={isSm ? 'none' : 'block'}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        © {moment().year()} UCO Admin Portal
      </Typography>
    </Stack>
  );
};

export default AppSideBar;
