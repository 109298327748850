import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EnqueueSnackbar, SnackbarKey } from 'notistack';

interface IAppState {
  enqueueSnackbar?: EnqueueSnackbar;
  closeSnackbar?: (key?: SnackbarKey | undefined) => void;
}

const initialState: IAppState = {};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState as IAppState,
  reducers: {
    setAppState(state, action: PayloadAction<IAppState>) {
      return action.payload;
    },
    resetAppState() {
      return initialState;
    },
  },
});

export const { setAppState, resetAppState } = appSlice.actions;
